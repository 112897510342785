import React, { useRef, useState } from "react";
import Portfolio1 from "../../assets/ui_ux.png";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import linkedin from "../../assets/linkedin.png";
import instagram from "../../assets/instagram.png";
import skype from "../../assets/skype.png";
import "./Contact.css";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const form = useRef();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const openSocialMediaProfile = (url) => {
    window.open(url, "_blank"); // Open the social media profile in a new tab
  };
  // const sendEmail = (e) => {
  //   setIsButtonDisable(true);
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_k3kj17f",
  //       "template_5vj2anf",
  //       form.current,
  //       "wS49sqIb5hphyJJIP"
  //     )
  //     .then(
  //       (result) => {
  //         setIsButtonDisable(false);
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  const showSuccessToast = () => {
    toast.success("Email sent successfully!", {
      position: "top-right",
      autoClose: 3000, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setIsButtonDisable(true)
    const formData = new FormData(form.current);

    // Check for blank data
    if (!formData.get("your_name") || !formData.get("your_email") || !formData.get("message")) {
      toast.error("Please fill out all fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    // Check for a valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.get("your_email"))) {
      toast.error("Please enter a valid email address!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
       
      });
      return;
    }

    setIsButtonDisable(true);

    emailjs
      .sendForm(
        "service_k3kj17f",
        "template_5vj2anf",
        form.current,
        "wS49sqIb5hphyJJIP"
      )
      .then(
        (result) => {
          showSuccessToast();
          console.log(result.text);
         
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contactPage">
      <ToastContainer autoClose={8000}/>
      <div id="contact">
        <h1 className="contactPageTitle">Contact Me</h1>
        <span className="contactDesc">
          Please fill out this form to discuss any work opportunity
        </span>
        <form className="contactForm" ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            className="name"
            placeholder="Your name"
            name="your_name"
          ></input>
          <input
            type="email"
            className="email"
            placeholder="Your email"
            name="your_email"
          ></input>
          <textarea
            name="message"
            rows={5}
            placeholder="Your message"
            className="msg"
          ></textarea>
          <button
            type="submit"
            value={"send"}
            className="submitButton"
            disabled={isButtonDisable}
          >
            Submit
          </button>
        </form>
      </div>
      <div className="links">
        <img
          src={instagram}
          alt="portfolio"
          className="link"
          onClick={() =>
            openSocialMediaProfile("https://www.instagram.com/_priyanshi_04/")
          }
        ></img>
        <img
          src={linkedin}
          alt="portfolio"
          className="link"
          onClick={() => {
            openSocialMediaProfile(
              "https://www.linkedin.com/in/priyanshipatel248/"
            );
          }}
        ></img>
        <img
          src={skype}
          alt="portfolio"
          className="link"
          onClick={() => {
            openSocialMediaProfile("skype:live:.cid.af96905a6fe0d031?chat");
          }}
        ></img>
      </div>
    </section>
  );
};

export default Contact;

import React from 'react'
import './footer.css'
 const Footer = () => {
  return (
   <footer className='footer'>
    Crafted with passion and precision by Priyanshi 👩🏻‍💻. </footer>
  )
}

export default Footer;

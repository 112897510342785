import React from "react";
import "./work.css";
import Portfolio1 from "../../assets/ui_ux.png";
import mobileApp_graphics from "../../assets/mobile_app_graphic.png";
import webApp_graphics from "../../assets/webApp_graphic.png";
const Work = () => {
  let techArray = [
    {
      id: 1,
      name: "html",
      image: require("../../assets/techIcons/html.png"),
    },
    
    {
      id: 2,
      name: "css",
      image: require("../../assets/techIcons/css.png"),
    },
    {
      id: 3,
      name: "javascript",
      image: require("../../assets/techIcons/javascript.png"),
    },
    {
      id: 4,
      name: "typescript",
      image: require("../../assets/techIcons/typescript.png"),
    },
    {
      id: 5,
      name: "tailwind",
      image: require("../../assets/techIcons/tailwind.png"),
    },
    {
      id: 9,
      name: "redux",
      image: require("../../assets/techIcons/redux.png"),
    },
    {
      id: 6,
      name: "vscode",
      image: require("../../assets/techIcons/vscode.png"),
    },
    {
      id: 7,
      name: "androidStudio",
      image: require("../../assets/techIcons/android_studio.png"),
    },
    {
      id: 8,
      name: "xcode",
      image: require("../../assets/techIcons/xcode.png"),
    },
    {
      id: 9,
      name: "postman",
      image: require("../../assets/techIcons/postman.png"),
    },
    {
      id: 11,
      name: "thunderClient",
      image: require("../../assets/techIcons/thunderclient.png"),
    },
    {
      id: 12,
      name: "github",
      image: require("../../assets/techIcons/github_mark.png"),
    },
  ];
  let testimonialArray = [
    {
      id: 1,
      clientName: "Mark",
      testimonial: `Priyanshi Patel's exceptional technical expertise and dedication played a key role in seamlessly transforming my Figma design into a fully functional React Native app. Her commitment to meeting deadlines and delivering outstanding results makes her a reliable and talented professional. I highly recommend her for any React Native development needs.`,
    },
    { id: 2, clientName: "Harsh Shah", testimonial: "Priyanshi's expertise and dedication significantly improved my two-year-old React Native project, enhancing its performance and stability. Her attention to detail, seamless communication, and commitment to excellence make her my top choice for future collaborations. Thank you, Priyanshi, for your exceptional work!" },
    { id: 3, clientName: "Manish Khamar", testimonial: "Priyanshi Patel's exceptional problem-solving skills and efficient bug resolution significantly improved my app. Her remarkable attention to detail and strong communication make her an outstanding React Native developer. I highly recommend Priyanshi for her expertise and dedication to delivering high-quality work." },
    { id: 4, clientName: "Jasmin Bhesaniya", testimonial: "Priyanshi's impressive React JS API integration skills elevated our project with seamless external data incorporation. Her attention to detail and efficient problem-solving make her the go-to choice for any React JS API integration needs. Thanks, Priyanshi, for your exceptional work!" },
  ];
  return (
    <section id="work">
      <h2 className="workTitle">What I serve?</h2>
      <span className="workDescription">
        I specialize in crafting innovative and user-centric digital solutions
        through bespoke app development and web development services. From
        intuitive mobile applications to dynamic web platforms, I leverage
        cutting-edge technologies to bring your ideas to life, ensuring a
        seamless and engaging user experience.
      </span>

      <div className="serviceView">
        <img src={mobileApp_graphics} alt="" className="workImage"></img>

        <section>
          <h2>Mobile App Development</h2>
          <span className="serviceDescription">
            Elevate your concepts with our Mobile App Development service.
            Specializing in React Native, I craft bespoke applications for
            Android and iOS, blending user-centric design, optimal performance,
            and custom solutions. Utilizing cutting-edge technologies, I
            seamlessly develop, deploy, and maintain apps to turn your unique
            vision into a stunning reality.
          </span>
        </section>
      </div>
      <div className="serviceView">
        <img src={webApp_graphics} alt="" className="workImage"></img>

        <section>
          <h2>Web App Development</h2>
          <span className="serviceDescription">
            Explore the possibilities with our React.js Web App Development
            service. We specialize in crafting tailor-made web applications,
            blending seamless performance, user-centric design, and unique
            solutions. Harnessing the power of React.js, we bring your ideas to
            life, developing, deploying, and maintaining web apps that embody
            your distinctive vision.
          </span>
        </section>
      </div>
      <h2 className="workTitle">What I use?</h2>
      <div className="techIconViewContainer">
        {techArray.map((item) => (
          <div className="techIconView">
            <img src={item.image} className="techImage" alt=""></img>
          </div>
        ))}
      </div>
      <h2 className="workTitle">Testimonials</h2>
      <div className="testimonialContainer">
        {testimonialArray.map((item) => (
          <div className="testimonialView">
            <h4>{item.clientName}</h4>
            <p>{item.testimonial}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Work;

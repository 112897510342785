import React from "react";
import Navbar from "./components/Navbar/navbar";
import Intro from "./components/Intro/intro";
import Education from "./components/Education/education";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/footer";
import About from "./components/About/about";
import Work from "./components/Work/work";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro />
      <About />
      <Education />
      <Work />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import "./education.css";
import bg from "../../assets/bg.png";

const Education = () => {
  return (
    <section id="education">
      <h2 className="workTitle">Education</h2>
      <div className="keyBar">
        <div className="keyBarText">
          <h2>Sal Engineering and Technical Institute</h2>
          <p>
            During my B.E. in Computer Engineering at Sal Engineering and
            Technical Institute, I delved into key subjects including Data
            Structures, Database Management Systems (DBMS), Networking, and
            Security, establishing a robust understanding of the fundamentals in
            Computer Engineering.
          </p>
        </div>
      </div>
      <h2 className="workTitle">Experience </h2>
      <div className="experienceContainer">
        <div className="companyBar">
          <div className="companyBarText">
            <h4>Nimblechapps Pvt Ltd.</h4>
            <p className="position">Mobile Developer</p>
            <p className="year">November 2022 - present</p>
            <p className="year">Ahmedabad, Gujarat</p>
          </div>
        </div>
        <div className="companyBar">
          <div className="companyBarText">
            <h4>3rd Digital Pvt Ltd.</h4>
            <p className="position">React Native Developer</p>
            <p className="year">June 2022 - October 2022</p>
            <p className="year">Ahmedabad, Gujarat</p>
          </div>
        </div>
        <div className="companyBar">
          <div className="companyBarText">
            <h4>Ysoft Solution LLP.</h4>
            <p className="position">React Native Developer</p>
            <p className="year">October 2021 - Jun 2022</p>
            <p className="year">Ahmedabad, Gujarat</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;

import React from "react";
import "./intro.css";
import bg from "../../assets/bg.png";
import { Link } from "react-scroll";
const Intro = () => {
  return (
    <section id="intro">
      <div className="introContent">
        <span className="hello">Hello,</span>
        <span className="introText">
          I'm Priyanshi Patel
          <br />
          <span className="introName">React Native Developer</span>
        </span>
        <p className="introPara">
          Elevate Your Vision, Transforming Ideas <br/>into Intuitive React Native
          Solutions.
        </p>
        
      </div>
      <img src={bg} alt="bg" className="bgImage"></img>
    </section>
  );
};

export default Intro;

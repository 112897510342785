
import React from "react";
import "./about.css"
const About = () => {
  return (
    <section id="about">
      <span className="meTitle">Who I am?</span>
      <span className="meDescription">
        I am a React Native Developer with over 2 years of experience. Holding a
        B.E. in Computer Engineering, I've worked on diverse projects,
        showcasing my skills in React Native, Next.js, and React.js. My approach
        involves creating seamless user experiences and solving complex
        problems. I'm committed to continuous learning, ensuring my skills stay
        current in this ever-evolving tech landscape. Let's connect and discuss
        how I can contribute to your next project!
      </span>
      <span className="meTitle">Key Highlights</span>
      <div className="keyBar">
          <div className="keyBarText">
            <h2>Experience Highlights:</h2>
            <p>
              In my journey, I've successfully contributed to diverse app
              categories, including Medical, E-Commerce, Social gathering, News
              and Events, Reminders.
            </p>
          </div>
        </div>
        <div className="keyBar">
          <div className="keyBarText">
            <h2>Passion for Excellence:</h2>
            <p>
            What sets me apart is not just the depth of my technical skills,
              but also my commitment to delivering excellence. From building
              applications from scratch to enhancing existing projects, I bring
              a wealth of experience to the table.
            </p>
          </div>
        </div>
        <div className="keyBar">
          <div className="keyBarText">
            <h2>Client-Focused Approach:</h2>
            <p>
            I thrive on understanding and meeting client needs, ensuring that
              every project I work on not only functions seamlessly but also
              exceeds expectations.
            </p>
          </div>
        </div>
    </section>
  );
};

export default About;
